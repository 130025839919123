# The First Table I made
## 2023/09/26

I heard that my grandpa used to handcraft his own farming tools, some of which were built to last a lifetime. My mom mentioned that those were the best tools she had ever used. I remember she was very sad when they all somehow went missing after he passed away. The lesson I learned is that things you create are very special.

I've always been interested in design and the process of creating something from scratch. Woodworking, in that sense, is fascinating because it allows you to transform rough wood into a beautiful creation that many people will appreciate. Being the creator of your own masterpiece. (Fun fact: Jesus was a carpenter too.)

While in Berlin for a month, I took a beginner woodworking class and became particularly interested in learning more joinery. And I made this table.

![Table 1 alt ><](https://github.com/jinnycho/jinnycho.github.io/blob/main/src/assets/photos/my_first_table_1.png?raw=true)

I initially intended to use it as a laptop table but ended up using it as a plant holder.

My woodworking journey hasn't stopped! I plan to take more courses in Brooklyn, including both introductory woodworking and handtools. I'll post more updates here!
